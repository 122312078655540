import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout pathEn="/en/">
    <SEO
      title="Erreur 404"
      description="La page qui correspond à cette adresse n'existe pas."
    />
    <h1>Erreur 404</h1>
    <p>La page qui correspond à cette adresse n'existe pas.</p>
    <p>
      <Link to="/">> Retourner sur la page d'accueil</Link>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </Layout>
)

export default NotFoundPage
